import '../styles/globals.css';
import 'react-phone-number-input/style.css';
import 'react-popper-tooltip/dist/styles.css';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import Meta from '../components/meta';
import { AuthProvider } from '../lib/auth';
import { MAPS_API_KEY, STRIPE_KEY } from '../lib/constants';
import * as gtag from '../lib/gtag';

const stripePromise = loadStripe(STRIPE_KEY);

if (!STRIPE_KEY) {
  console.warn('Stripe key not found in environment');
}
if (!MAPS_API_KEY) {
  console.warn('Google Maps key not found in environment');
}

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <script defer src={`https://maps.googleapis.com/maps/api/js?key=${MAPS_API_KEY}&libraries=places`}></script>
      </Head>
      <Meta />

      <Elements stripe={stripePromise}>
        <AuthProvider>
          <Component {...pageProps} />
        </AuthProvider>
      </Elements>
    </>
  );
}

export default MyApp;
