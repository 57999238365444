/**
 * Constants and configuration.
 */

export const API_URL = process.env.NEXT_PUBLIC_API_URL || 'https://api.postdates-dev.com';
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

export const CONTACT_PHONE_NUMBER = process.env.NEXT_PUBLIC_CONTACT_PHONE;
export const MAPS_API_KEY = process.env.NEXT_PUBLIC_MAPS_API_KEY;
export const STRIPE_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY;
export const MAPBOX_KEY = process.env.NEXT_PUBLIC_MAPBOX_KEY;

export const VERIFICATION_SERVICE_ID = process.env.TWILIO_VERIFICATION_SERVICE_ID;
export const TWILIO_ACCOUNT_SID = process.env.TWILIO_ACCOUNT_SID;
export const TWILIO_AUTH_TOKEN = process.env.TWILIO_AUTH_TOKEN;

export const DEFAULT_SUBTOTAL = 2999; // Default delivery base amount in cents.
export const DEFAULT_PLATFORM_FEE = 399; // Default delivery platform fee in cents.

export const SERVICE_AIRPALS = 'airpals';
export const SERVICE_GOURMET_RUNNER = 'gourmet-runner';

export const SERVICE_HOURS = [
  { name: SERVICE_GOURMET_RUNNER, pickupHours: { min: 11, max: 20 }, dropoffTime: { min: 30, range: 30 } },
  { name: SERVICE_AIRPALS, pickupHours: { min: 9, max: 13 }, dropoffTime: { min: 60, range: 120 } },
];

export const BASERATE_BY_CITY = [
  { city: 'LA', service: SERVICE_GOURMET_RUNNER, baserate: 2499 },
  { city: 'NYC', service: SERVICE_AIRPALS, baserate: 2999 },
];

export const ZIP_WHITELIST_GOURMET = [];

export const ZIP_WHITELIST_AIRPALS = [];

export const ZIP_COURIERS_WHITELIST = [
  { name: SERVICE_GOURMET_RUNNER, zips: ZIP_WHITELIST_GOURMET },
  { name: SERVICE_AIRPALS, zips: ZIP_WHITELIST_AIRPALS },
];
