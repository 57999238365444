import Head from 'next/head';

export default function Meta(): JSX.Element {
  return (
    <Head>
      <title>Postdates</title>
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
      <link rel="shortcut icon" href="/favicon/favicon.ico" />
      <link rel="preload" href="/fonts/Matter-Regular.woff" as="font" crossOrigin="" />
      <link rel="preload" href="/fonts/Matter-Medium.woff" as="font" crossOrigin="" />
      <link rel="preload" href="/fonts/Matter-Bold.woff" as="font" crossOrigin="" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
      <meta name="description" content="Get your stuff back" />
      <meta
        property="og:title"
        content="Postdates | The First App for Breaking Up | Get Your Stuff Back | Order Online"
      />
      <meta property="og:url" content="https://postdates.co" />
      <meta property="og:description" content="Get your stuff back" />
      <meta property="og:image" content="/images/og-image.gif" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content="https://postdates.co/images/og-twitter.jpg" />
    </Head>
  );
}
