import { GA_TRACKING_ID } from './constants';

declare global {
  interface Window {
    gtag: (type: string, name: string, params: Record<string, any>) => void;
  }
}

if (typeof window != 'undefined') {
  window.gtag = window.gtag;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string): void => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: Record<string, any>): void => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
